@import './src/global.scss';
.form {
	max-width: 630px;
	margin: 0 auto;
	&__masthead {
		@include responsiveUnit(margin-bottom, 0px, 8px);
	}
	&__submit {
		@include responsiveUnit(margin-top, 16px, 24px);
		text-align: center;
	}
}

.field {
	&__label {
		margin-bottom: 0.25em;
	}
	&__input > * {
		display: block;
		width: 100%;
		padding: 0 16px;
		height: 42px;
		font-size: 16px;
		border: none;
		outline: none;
		background: var(--body-bg-color);
		color: var(--color-body);
		border: 1px solid #1f1e21;
	}
	&__input > textarea {
		resize: none;
		height: 162px;
		padding: 16px;
	}
	&__error {
		color: var(--color-validation);
		font-size: 80%;
		margin-top: 0.25em;
	}
}
.select {
	position: relative;
	&__inner {
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 100%;
		svg {
			fill: currentColor;
		}
	}
	select {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 2;
		opacity: 0;
		width: 100%;
		border: none;
		cursor: pointer;
	}
}
.recaptcha {
	display:flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding:16px 0;
}