@import './src/global.scss';
.app {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	position: relative;
}

.top {
	position: relative;
}

.overlayMenu {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	background: #000;
	z-index: 9;
	padding-top: var(--header-height);
	transition: opacity 0.3s;
	&.enter,
	&.exitActive {
		opacity: 0;
	}
	&.enterActive {
		opacity: 1;
	}
	a {
		color: inherit;
	}
	.menu {
		&__inner {
			max-width: 464px;
			color: #fff;
			&.float {
				position: absolute;
				right: 10%;
			}
		}
		&__links {
			@include responsiveUnit(font-size, 48px, 64px);
			margin: 0 0 32px;
			padding: 0;
			list-style: none;
			li {
				display: block;
			}
			a {
				display: inline-block;
				color: inherit;
				border-bottom: 1px solid var(--brand100);
				font-family: var(--font-secondary);
			}
		}
	}
}

.applyFormOverlay {
	@include responsiveUnit(padding, 32px, 72px);
	position: fixed;
	top: 0;
	left: 0;
	background: #000;
	z-index: 101;
	color: #fff;
	max-width: 640px;
	height: 100vh;
	overflow: auto;
	-webkit-overflow-scrolling: touch;
	transition: all 0.3s;
	&.enter,
	&.exitActive {
		transform: translateX(100%);
	}
	&.enterActive {
		transform: translateX(0);
	}
	.close {
		@include button-reset;
		@include responsiveUnit(top, 24px, 40px);
		@include responsiveUnit(right, 24px, 40px);
		position: absolute;
		svg {
			fill: #fff;
		}
	}
	.form {
		@include responsiveUnit(margin-top, 16px, 32px);
	}
}

.header {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	height: var(--header-height);
	z-index: 10;
	color: #fff;
	.inner {
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 100%;
	}
	&__right {
		display: flex;
		align-items: center;
	}
	.quickLink {
		margin-right: 32px;
	}
}

.masthead {
	padding-top: var(--header-height);
	background-size: cover;
	background-position: center center;
	color: #fff;
	&.basic {
		position: relative;
		&:before {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			background: rgba(#232429, 0.5);
			content: '';
			pointer-events: none;
		}
		& > * {
			position: relative;
			z-index: 1;
		}
		h1 {
			@include responsiveUnit(padding-top, 0px, 80px);
			@include responsiveUnit(padding-bottom, 32px, 72px);
		}
	}
	&.noMask:before {
		display: none;
	}
}

.mastheadNav {
	display: flex;
	& > a {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 112px;
		flex: 1;
		background-color: rgba(#1a191b, 0.8);
		transition: all 0.15s;
		color: #fff;
	}
	:global(a.active) {
		background-color: var(--body-bg-color);
		color: var(--color-body);
	}
}

.quickLink {
	display: flex;
	align-items: center;
	svg {
		fill: currentColor;
		margin-right: 8px;
	}
	a {
		color: inherit;
	}
}

.banner {
	@include responsiveUnit(padding-top, 32px, 72px);
	@include responsiveUnit(padding-bottom, 32px, 72px);
	background: #fff;
}

.body {
	@include responsiveUnit(padding-top, 32px, 72px);
	@include responsiveUnit(padding-bottom, 48px, 72px);
}

.container {
	max-width: var(--container-width);
	margin: 0 auto;
	padding-left: var(--grid-basis);
	padding-right: var(--grid-basis);
	position: relative;
	&.slim {
		max-width: 864px;
	}
}

.bottom {
	margin-top: auto;
}

.section + .section {
	@include responsiveUnit(margin-top, 48px, 96px);
}
