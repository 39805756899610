$screen-min: 600px;
$screen-max: 1180px;
$screen-diff: ($screen-max / 1px - $screen-min / 1px);

@mixin responsiveUnit($unit, $min, $max) {
	#{$unit}: $min;
	@media (min-width: $screen-min) and (max-width: $screen-max) {
		#{$unit}: calc(#{$min} + (100vw - #{$screen-min}) / #{$screen-diff} * #{($max / 1px - $min / 1px)});
	}
	@media (min-width: #{$screen-max + 1}) {
		#{$unit}: $max;
	}
}

@mixin button-reset() {
	background: none;
	padding: 0;
	border: none;
	cursor: pointer;
	outline: none;
	color: inherit;
}
