@import './src/global.scss';
.twocol {
	&__grid {
		justify-content: space-between;
		max-width: 1000px;
		margin: 0 auto;
		position: relative;
		&[inline] {
			&:before {
				position: absolute;
				top: 0;
				bottom: 0;
				left: 50%;
				width: 1px;
				background: rgba(#afaba4, 0.5);
				content: '';
			}
		}
		&[stacked] {
			align-items: center;
		}
	}
	&__cell {
		max-width: 440px;
	}
}
