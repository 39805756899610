@import './src/global.scss';
.wrapper {
	display: flex;
	flex-direction: column;
	background: #fff;
	color: var(--color-body);
	min-height: 60vh;
	max-width: 768px;
	margin: 0 auto;
	overflow: hidden;
	padding: 24px;
	&__upper {
		flex: 1;
		position: relative;
	}
	&__lower {
		text-align: center;
		margin-top: 24px;
		a {
			color: var(--brand100);
			text-decoration: underline;
		}
	}
}
:global(.screen-large) .wrapper {
	padding-top: 7.5vh;
}

.step {
	max-width: 400px;
	margin: 0 auto;
	transition: all 0.5s;
	position: relative;
	text-align: center;
	&.enter {
		transform: translateX(100%);
		opacity: 0;
	}
	&.enterActive,
	&.exit {
		transform: translateX(0);
		opacity: 1;
	}
	&.exitActive {
		position: absolute;
		top: 0;
		transform: translateX(-100%);
		opacity: 0;
	}
	&__icon {
		fill: var(--brand100);
		margin-bottom: 8px;
	}
	&__options {
		margin-top: 24px;
		button + button {
			margin-left: 16px;
		}
	}
}

.progressBar {
	height: 4px;
	background: rgba(#1a191b, 0.1);
	border-radius: 4px;
	overflow: hidden;
	margin-top: 24px;
	&__bar {
		background: var(--brand100);
		height: 100%;
	}
}

.remaining {
	opacity: 0.5;
	margin-top: 16px;
}
