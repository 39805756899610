@import './src/global.scss';
.outer {
}
:global(.screen-small) .outer {
	max-width: 492px;
	margin: 0 auto;
}

.row.alt[inline] {
	flex-direction: row-reverse;
}

.shadow {
	display: inline-block;
	position: relative;
	padding-bottom: 5%;
	padding-left: 5%;
	&:before {
		position: absolute;
		top: 5%;
		bottom: 0;
		left: 0;
		right: 5%;
		background: var(--brand100);
		content: '';
	}
	img {
		position: relative;
		z-index: 1;
	}
}
.row.alt .shadow {
	padding-left: 0;
	padding-right: 5%;
	&:before {
		right: 0;
		left: 5%;
	}
}

.image {
	text-align: center;
}

.text {
	display: flex;
	align-items: center;
	justify-content: center;
	&__block {
		max-width: 416px;
	}
}

.cta {
	margin-top: 16px;
}
