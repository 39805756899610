@import './src/global.scss';
.dialog {
	display: flex;
	align-items: center;
	justify-content: center;
	position: fixed;
	z-index: 102;
	top: 0;
	left: 0;
	min-height: 100vh;
	min-width: 100vw;
	transition: opacity 0.3s;
	&.enter,
	&.exitActive {
		opacity: 0;
		.dialog__body {
			transform: translateY(33.3333%);
		}
	}
	&.enterActive {
		opacity: 1;
		.dialog__body {
			transform: translateY(0);
		}
	}
	&__overlay {
		background: rgba(#000, 0.5);
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}
	&__body {
		background: #fff;
		width: 100%;
		max-width: 480px;
		padding: 32px;
		box-shadow: 0 0 20px 0 rgba(#000, 0.15);
		position: relative;
		transition: transform 0.3s;
	}
}
