@import './src/global.scss';
.footer {
	--footer-bg-lite: #1f1e21;
	--footer-bg-dark: #1a191b;
	--footer-bg-contact: var(--footer-bg-lite);
	color: #fff;
	background: black;
	a {
		color: inherit;
	}
}

.contact {
	background-color: var(--footer-bg-contact);
	border-bottom: 1px solid transparent;
	padding: 30px 0;
	transition: all 0.3s;
	&.active {
		--footer-bg-contact: --footer-bg-dark;
		border-color: rgba(#979797, 0.5);
	}
	&__headline {
		text-align: center;
		.notch {
			display: inline-block;
			transition: transform 0.3s;
			margin-left: 8px;
			vertical-align: middle;
			fill: #fff;
		}
	}
	button.contact__headline {
		@include button-reset;
		display: block;
		margin: 0 auto;
	}
}

.formWrapper {
	@include responsiveUnit(margin-top, 16px, 24px);
}

.inner {
	padding: 30px 0;
}

.badge {
	margin: 0 auto;
}

.nav {
	& > a {
		display: block;
		color: #fff;
		text-decoration: none;
		&:hover {
			text-decoration: underline;
		}
	}
	svg {
		fill: var(--brand100);
	}
}

.social {
	margin-top: 16px;
	a {
		display: inline-block;
	}
	a + a {
		margin-left: 16px;
	}
}
:global(.screen-large) {
	.social {
		display: flex;
		flex-direction: column;
		justify-content: center;
		text-align: center;
		margin-top: 0;
		a {
			display: block;
		}
		a + a {
			margin-left: 0;
			margin-top: 8px;
		}
	}
}

.copyright {
	opacity: 0.5;
	margin-top: 32px;
	font-size: 14px;
}
:global(.screen-large) {
	.copyright {
		margin-top: 64px;
	}
}
