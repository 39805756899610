@import './src/global.scss';
.virtualTourLink {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 12px;
	svg {
		display: block;
		margin-right: 8px;
	}
}
