@import './src/global.scss';
.grid {
	display: flex;
	margin-left: calc(var(--grid-basis) * -1);
	margin-right: calc(var(--grid-basis) * -1);
	&.stacked {
		flex-direction: column;
	}
}

.cell {
	padding-left: var(--grid-basis);
	padding-right: var(--grid-basis);
}
