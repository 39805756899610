@import './src/global.scss';
@mixin headline() {
	font-family: var(--font-secondary);
	line-height: 1.4;
	font-weight: 400;
}

.fs {
	line-height: 1.625;
	p {
		margin: 1em 0;
		&:first-child {
			margin-top: 0;
		}
		&:last-child {
			margin-bottom: 0;
		}
	}
	a {
		color: var(--color-body-link);
		text-decoration: underline;
	}
	.fs3,
	.fs2 {
		margin-bottom: 12px;
		&:last-child {
			margin-bottom: 0;
		}
	}
	.fs6 + .fs4 {
		margin-top: 16px;
	}
}

.fs1 {
	@include headline;
	@include responsiveUnit(font-size, 40px, 80px);
	line-height: 1;
}

.fs2 {
	@include headline;
	@include responsiveUnit(font-size, 24px, 32px);
}

.fs3 {
	@include headline;
	@include responsiveUnit(font-size, 24px, 28px);
}

.fs4 {
	@include headline;
	@include responsiveUnit(font-size, 20px, 24px);
}

.fs5 {
	@include headline;
	@include responsiveUnit(font-size, 30px, 40px);
}

.fs6 {
	font-size: 12px;
	line-height: 1;
	text-transform: uppercase;
	font-weight: 500;
	letter-spacing: 4px;
}

.left {
	text-align: left;
}

.center {
	text-align: center;
}

.right {
	text-align: right;
}

.justify {
	text-align: justify;
}

.noWrap {
	line-height: 1;
	white-space: nowrap;
}
