@import './src/global.scss';
.outer {
	max-width: 640px;
	margin: 0 auto;
	.quote {
		@include responsiveUnit(font-size, 32px, 40px);
		font-family: var(--font-secondary);
		text-align: center;
		font-weight: 900;
		line-height: 1.2;
		color: var(--brand100);
		cursor: grab;
		user-select: none;
		p {
			margin: 0;
		}
	}
	:global(.tns-nav) {
		text-align: center;
		margin-top: 32px;
		button {
			@include button-reset;
			width: 8px;
			height: 8px;
			background-color: rgba(#1a191b, 0.2);
			border-radius: 50%;
		}
		button + button {
			margin-left: 8px;
		}
	}
	:global(button.tns-nav-active) {
		background-color: var(--brand100);
	}
}
