@import './src/global.scss';
.masthead {
	@include responsiveUnit(padding-bottom, 32px, 120px);
	&__inner {
		position: relative;
	}
	&__headline {
		text-align: center;
		margin-top: 32px;
	}
	&__h1 {
		margin-bottom: 24px;
	}
	&__video {
		display: block;
		margin: 0 auto;
		max-width: 100%;
		height: auto;
	}
	&.fancy {
		.masthead {
			&__headline {
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				text-align: left;
				margin-top: 0;
			}
			&__image {
				margin: 0 auto;
				max-width: 85%;
			}
			&__video {
				max-width: 75%;
			}
		}
	}
}

.introCta {
	margin-top: 16px;
}
