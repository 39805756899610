@import './src/global.scss';
.masthead {
	padding-top: 60vh;
}

.header.stacked {
	.header__a {
		text-align: center;
	}
	.header__b {
		&:before {
			display: block;
			width: 64px;
			height: 1px;
			background: #afaba4;
			content: '';
			margin: 24px auto;
		}
	}
}
.header.flex {
	display: flex;
	justify-content: space-between;
}

.title {
	margin-bottom: 16px;
	max-width: 860px;
}

.intro {
	max-width: 960px;
	margin-top: 32px;
}

.features {
	margin: 0;
	padding-left: 1em;
}

.intro[inline] .features {
	margin-left: 48px;
}

.virtualTourEmbed {
	width: 100%;
	height: 60vh;
}
