@import './src/global.scss';
.button {
	display: inline-block;
	height: 64px;
	padding: 0 28px;
	outline: none;
	font-size: 13px;
	line-height: 60px;
	appearance: none;
	border-radius: 4px;
	transition: all 0.3s;
	text-transform: uppercase;
	cursor: pointer;
	letter-spacing: 1.25px;
	border: 1px solid transparent;
	&.primary {
		border-color: var(--brand100);
		background: transparent;
		color: var(--brand100);
		@media (hover: hover) {
			&:hover {
				background: var(--brand100);
				color: #fff;
			}
		}
	}
	&.secondary {
		background: var(--brand100);
		color: #fff;
		border-color: var(--brand100);
		@media (hover: hover) {
			&:hover {
				background: var(--brand90);
			}
		}
	}
	&.tertiary {
		background: transparent;
		color: #fff;
		border-color: #fff;
		@media (hover: hover) {
			&:hover {
				background-color: #fff;
				color: var(--brand100);
			}
		}
	}
}

a.button {
	text-decoration: none;
}
