@import './src/global.scss';
.gallery {
	display: grid;
	grid-template-columns: repeat(auto-fill, 33.3333%);
	margin: calc(var(--grid-basis) / -2);
	&__cell {
		padding: calc(var(--grid-basis) / 2);
	}
	img {
		width: 100%;
		max-width: none;
		cursor: pointer;
	}
}
:global(.screen-small) .gallery {
	display: block;
}
