@import './src/global.scss';
.listings {
}

.listing {
	background: #fff;
	box-shadow: 0 1px 2px 0 rgba(#000, 0.1);
	.primary {
		padding: 32px;
		&__title {
			display: inline-block;
			text-decoration: none;
			margin-bottom: 12px;
		}
		&__cta {
			white-space: nowrap;
			> * + * {
				margin-left: 8px;
			}
		}
	}
}

.listing.flex {
	display: flex;
	.leadImage {
		flex-shrink: 1;
	}
	.primary {
		display: flex;
		flex: 1;
		align-items: center;
		justify-content: space-between;
		min-width: 480px;
		&__b {
			margin-left: 32px;
		}
	}
}

.listing.stacked {
	.primary {
		&__a {
			text-align: center;
		}
		&__b {
			&:before {
				display: block;
				width: 64px;
				height: 1px;
				background: #afaba4;
				content: '';
				margin: 24px auto;
			}
		}
	}
}

:global(.screen-small) .listing {
	max-width: 415px;
	margin: 0 auto;
}

.listing + .listing {
	margin-top: 16px;
}
